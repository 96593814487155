import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import $ from 'jquery'
import QuoteModal from "./../../../../components/layout/QuoteModal";
import {PaintColorHex, PaintColorName, SwainColorHex} from "../../ColorCodeHelper";

class ThicknessToolbar extends React.Component {

    state = {
      submittingQuote: false,
      quoteModal: false,
    }

    findOpaqueOuterCircle=(size)=>{
        let circles = this.props.canvas.getObjects('circle'),
            outerCircles=[];
        let finalOuterCircle = {
            fill:null,
            stroke:null,
            size
        }
        // seperate circles by types
        // @todo: use for loop
        circles.forEach(circle=>{
            if(circle.isOuterCircle){
                outerCircles.push({
                    size : this.props.circle_outer_diameter,
                    stroke: circle.strokeWidth>0 ? this.reduceDesignColor(circle.stroke):{hex:'transparent', name:'Transparent'},
                    fill:circle.fill === 'rgba(0,0,0,0)'?{hex:'transparent', name:'Transparent'}:this.reduceDesignColor(circle.fill)
                })
            }
        })
        //find outer opaque circle
        outerCircles.forEach((outerCircle,index)=>{
            if(outerCircle.fill.hex !== 'transparent')finalOuterCircle.fill = outerCircle.fill;
            if(outerCircle.stroke.hex !== 'transparent')finalOuterCircle.stroke = outerCircle.stroke;
        })
        if(finalOuterCircle.fill === null && finalOuterCircle.stroke === null) return null
        return(finalOuterCircle)
    }
    findOpaqueCenterCircle=(size)=>{
        let circles = this.props.canvas.getObjects('circle'),
            allCircles=[];
        let finalOuterCircles = []
        // seperate circles by types
        // @todo: use for loop
        circles.forEach((circle)=>{
            if(circle.isCenterCircle){
                allCircles.push({
                    type:'center',
                    stroke: circle.strokeWidth>0 ? this.reduceDesignColor(circle.stroke):{hex:'transparent', name:'Transparent'},
                    fill:circle.fill === 'rgba(0,0,0,0)'?{hex:'transparent', name:'Transparent'}:this.reduceDesignColor(circle.fill)
                })
            }else if(circle.isOuterCircle){
                allCircles.push({
                    type:'outer',
                    stroke: circle.strokeWidth>0 ? this.reduceDesignColor(circle.stroke):{hex:'transparent', name:'Transparent'},
                    fill:circle.fill === 'rgba(0,0,0,0)'?{hex:'transparent', name:'Transparent'}:this.reduceDesignColor(circle.fill)
                })
            }
        })
        //find outer opaque circle
        let lastCenterCircle = {
            fill:null,
            stroke:null,
            size,
        };
        allCircles.forEach((circle,index)=>{
            let finalOuterCircle = {
                fill:null,
                stroke:null,
                type:circle.type
            }
            if(circle.fill.hex !== 'transparent')finalOuterCircle.fill = circle.fill;
            if(circle.stroke.hex !== 'transparent')finalOuterCircle.stroke = circle.stroke;
            finalOuterCircles.push(finalOuterCircle);
        })
        for(let i = finalOuterCircles.length-1 ; i>=0 ; i-- ){
            if(finalOuterCircles[i].type === 'center'){
                lastCenterCircle.stroke  = finalOuterCircles[i].stroke;
                break;
            }
        }
        for(let i = finalOuterCircles.length-1 ; i>=0 ; i-- ){
            if(finalOuterCircles[i].fill){
                lastCenterCircle.fill  = finalOuterCircles[i].fill;
                break;
            }
        }
        if(lastCenterCircle.fill === null && lastCenterCircle.stroke === null) return null
        return lastCenterCircle;
    }
    sendQuote = ({ fullName: name, email, phone, zipcode, subject, message }) => {
        this.clearSelection();
        this.setState({submittingQuote: true})
        const thickness = $('input[name="mat-thickness"]:checked').val();
        const matData = [{}];
        let allLogos = [];
        // matData[0].matSize = (this.props.wrestling_config.width * 2) + " x " + (this.props.wrestling_config.height * 2);
        if (
            this.props.wrestling_config.width === "12" ||
            this.props.wrestling_config.width === 12
            // this.props.wrestling_config.width === 30
        ) {
            matData[0].matSize =
                this.props.wrestling_config.width +
                "m x " +
                this.props.wrestling_config.height +
                "m";
        } else {
            matData[0].matSize =
                this.props.wrestling_config.width +
                "ft x " +
                this.props.wrestling_config.height+"ft";
        }
        matData[0].matColor = this.props.mat_color;
        let matFonts = [];
        let matShapes = [];
        if (this.props.canvas.getObjects("path-group").length > 0){
            let shapes = this.props.canvas.getObjects("path-group");
            shapes.forEach((item,index)=>{
                console.log({index,item});
                let paths = item.paths;
                let colors =[];
                paths.forEach(path=>{
                    let hex = this.reduceDesignColorMin(path.fill)
                    if(!colors.includes(hex)){
                        colors.push(hex);
                    }
                })
                matShapes.push({
                    colors : colors,
                })
            })
            matData[0].shapes = matShapes;
        }
        if (this.props.canvas.getObjects("curvedText").length > 0) {
            let curvedTexts = this.props.canvas.getObjects("curvedText");
            curvedTexts.forEach((item,index)=>{
                console.log({index,item});
                if(item && item.text !== ''){
                    matFonts.push({
                        text : item.text,
                        fontFamily : item.fontFamily,
                        fill : item.fill,
                        outline: item.stroke,
                        outlineSize: item.strokeWidth,
                    })
                }
            })

            matData[0].text = this.props.text_style ? this.props.text_style.text : "";
            matData[0].font = this.props.text_style
                ? this.props.text_toolbar_config.fontFamily
                : "";
            matData[0].fontColor = this.props.text_toolbar_config.fill;
        }
        matData[0].curvedTexts = matFonts;
        if(this.props.canvas.getObjects('circle').length > 0){
            if (
                this.props.circle_outer_diameter === 260 &&
                (this.props.wrestling_config.width === "12" ||
                    this.props.wrestling_config.width === 12 ||
                    this.props.wrestling_config.width === 30)
            ) {
                matData[0].circle = "9m x 9m";
            } else {
                matData[0].circle =
                    this.props.circle_outer_diameter +
                    "ft x " +
                    this.props.circle_inner_diameter+"ft";
            }
        }else{
            matData[0].circle = "";
        }

        if(matData[0].circle == "0ft x 0ft"){
            matData[0].circle = "";
        }
        if(matData[0].circle && this.props.canvas.getObjects('circle').length > 0){
            matData[0].color_fill = this.reduceDesignColor(this.props.canvas.getObjects('circle')[0].fill);
            matData[0].color_stroke = this.reduceDesignColor(this.props.canvas.getObjects('circle')[0].stroke);
        }
        //adding practice circles
        if(this.props.canvas.getObjects('circle').length > 0){
            let practiceCircles = [];
            this.props.canvas.getObjects('circle').forEach(circle=>{
                //let
                //let newCircle = `${circle.strokeWidth>0 ? 'OUTLINE : '+circle.stroke:''} ${circle.fill === 'rgba(0,0,0,0)'?'':'FILL : '+circle.fill}`
                let newCircle = this.reduceDesignColor(circle.stroke);
                if(circle.radius === 43 && !circle.isCenterCircle){
                    practiceCircles.push(newCircle)
                }
            })
            matData[0].practrice_circles = practiceCircles;
        }
        matData[0].outer_circle = this.findOpaqueOuterCircle(this.props.circle_outer_diameter);
        matData[0].center_circle = this.findOpaqueCenterCircle(this.props.circle_inner_diameter);
        if(this.props.canvas.getObjects('group').length > 0){
            this.props.canvas.getObjects('group').forEach(rect=>{
                if(rect.isStartMark){
                    matData[0].start_marks = [
                        this.reduceDesignColor(rect._objects[1].stroke),
                        this.reduceDesignColor(rect._objects[2].stroke),
                        this.reduceDesignColor(rect._objects[3].stroke),
                    ]
                }
            })
        }
        if(this.props.canvas.getObjects('image').length > 0){
            let images = this.props.canvas.getObjects("image");
            images.forEach((image)=>{
                console.log(image.source)
                allLogos.push(image.source)
            })
        }
        matData[0].logos = allLogos;
        console.log(matData[0].circle);
        matData[0].thickness = thickness;

        const fc = $('input[name="mat-flexi-connect"]:checked').val();
        matData[0].fc = fc ? "Yes" : null;

        matData[0].svg = this.props.canvas.toSVG();
        matData[0].png = this.props.canvas.toDataURL();
        //matData[0].logos = this.props.uploadedLogos;

        const data = {
            name,
            email,
            phone,
            zipcode,
            subject:`${subject}`,
            message,
            matData,
        };
        console.log(data);
        axios
            .post("/api/quote", data)
            .then((res) => {
              this.setState({quoteModal: false, submittingQuote: false})
                alert("Successfully saved!");
            })
            .catch((err) => {
              this.setState({quoteModal: false, submittingQuote: false})
                alert("An unexpected error occurred. Please contact Admin");
            });
    };

    reduceDesignColorMin=(colorCode)=>{
        if(colorCode.indexOf('rgb') >= 0){
            let a = colorCode.split("(")[1].split(")")[0];
            a = a.split(",");
            let b = a.map(function(x){                      //For each array element
                x = parseInt(x).toString(16);      //Convert to a base16 string
                return (x.length==1) ? "0"+x : x; //Add zero if we get only one character
            });
            colorCode = "#"+b.join("");
        }
        return colorCode;
    }
    reduceDesignColor=(colorCode)=>{
        if(colorCode === 'rgba(0,0,0,0)'){
            return 'transparent';
        }
        if(colorCode.indexOf('rgb') >= 0){
            let a = colorCode.split("(")[1].split(")")[0];
            a = a.split(",");
            let b = a.map(function(x){                      //For each array element
                x = parseInt(x).toString(16);      //Convert to a base16 string
                return (x.length==1) ? "0"+x : x; //Add zero if we get only one character
            });
            colorCode = "#"+b.join("");
        }
        let colorName  = {hex:colorCode, name:colorCode}, // send color code and name to server
            colorNames = Object.keys(PaintColorHex);
        colorNames.forEach(color=>{
            if(PaintColorHex[color] === colorName.hex){
                colorName.name = PaintColorName[color]
            }
        })
        return colorName;
    }

    clearSelection = () =>{
        this.props.canvas.deactivateAll().renderAll()
        // copying clear selection event data
        //$('.deleteBtn').css('visibility', 'hidden')
        $('.material-color-pane').css({ display: 'none' })
        $('.circle-color-pane').css({ display: 'none' })
        $('.text-color-pane').css({ display: 'none' })
        $('.artwork-color-pane').css({ display: 'none' })
        $('.custom-colors').css('display', 'none')
        $('.advanced_span').css('display', 'none')
        $('#rgb').css('display', 'none')
        $('#cmyk').css('display', 'none')

        $('#bold').removeClass('active')
        $('#italic').removeClass('active')
        $('#underline').removeClass('active')
        $('#capital').removeClass('active')
        $('#left-align').removeClass('active')
        $('#center-align').removeClass('active')
        $('#right-align').removeClass('active')
        //$('.slct-font-size').val($('.slct-font-size option:first').val())
        //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
        $('#line_ht_get').val(1)
        $('#line_ht_get').css({
            background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
        })
        $('#line_ht_set').val(1)
        $('#spacing_get').val(1)
        $('#spacing_get').css({
            background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
        })
        $('#spacing_set').val(1)
        $('#text-color-bind').css({ background: 'black' })
        $('#size_get').val(0)
        $('#size_get').css({
            background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
        })
        $('#size_set').val(0)
        $('#outline-color-bind').css({ background: 'black' })
        $('#selected_text').val('Add Text')
        $('.add-text-btn').text('Add Text')
    }

    render() {
      return (
        <>
          <div className="adddiv">
            <h2>Select Thickness</h2>
          </div>
          <div className="adddiv pb-15">
            <div className="mat-thickness">
              <div>
                <label>
                  <input type="radio" name="mat-thickness" value='1 5/8"' />1 5/8"
                </label>
                <label style={{paddingLeft: "20px"}}>
                  <input type="radio" name="mat-thickness" value="2" />2"
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" name="mat-flexi-connect" />Add FLEXI-Connect Built-in, tapeless connection
                </label>
              </div>
            </div>
          </div>
          <div className="adddiv">
              <QuoteModal 
                sendQuote={this.sendQuote} 
                designer={'wrestling'} 
                message={'WRESTLING MAT'}
                show={this.state.quoteModal}
                submitting={this.state.submittingQuote}
                toggleModal={(val) => this.setState({quoteModal: val})} 
              />
          </div>
        </>
      )
    }
}


const mapStateToProps = state => {
    return {
        wrestling_config: state.wrestling_config,
        canvas: state.canvas,
        text_style: state.text_style,
        fontColor: state.fontColor,
        uploadedLogos: state.uploadedLogos,
        circle_outer_diameter: state.circle_outer_diameter,
        circle_inner_diameter: state.circle_inner_diameter,
        text_toolbar_config: state.text_toolbar_config,
        mat_color: state.mat_color,
    };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ }, dispatch);
};

const ThicknessToolbarConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThicknessToolbar);


class ThicknessToolSidebar extends React.Component {
  closeNav = () => {
    document.getElementById("mySidenav6").style.width = "0";
  };

  render() {
    return (
      <>
        <div
          id="thickness"
          className="h100 second-menu fordeskview"
          style={{ display: "none" }}
        >
          <ThicknessToolbarConnect />
        </div>
        <div
          className="text-center h100 pad0 mtopneg sidenav2 formobview wo"
          id="mySidenav6"
        >
          <a
            style={{ color: "#fff" }}
            href="#"
            className="closebtn"
            onClick={() => this.closeNav()}
          >
            &times;
          </a>
          <ThicknessToolbarConnect />
        </div>
      </>
    );
  }
}

export default ThicknessToolSidebar;
