import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import $ from "jquery";

const ImageList = (props) => {
  const files = props.files;
  if (files.length === 0) return <div />;
  else
    return files.map((file, idx) => (
      <div className="col-md-6 col-sm-6" key={idx}>
        <div className="temrows mat-lft-repeat-blk">
          {/* <img
            src={require(`./../../../../../../artworks/${file}`)}
            alt="Template"
            className="img-responsive"
            onClick={props.addNewArtWork}
          /> */}
        </div>
      </div>
    ));
};

const OneColorLogos = (props) => {
  const [files, setFiles] = useState();
  useEffect(() => {
    axios
      .post("/api/load_logo", { dir: "1-Color" })
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (files === undefined) return <div />;
  return (
    <>
      {files.map((file, index) => {
        return (
          <div className="col-md-6 col-sm-6" key={index}>
            <div className="temrows mat-lft-repeat-blk"
              style={{
                border: '2px solid #34495e',
                background: '#5c6b7b',
                borderRadius: '15px',
                padding: '10px',
              }}
            >
              <img
                src={require(`./../../../../assets/logos/1-Color/${file}`)}
                alt="Template"
                className="img-responsive"
                onClick={props.addNewArtWork}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const TwoColorLogos = (props) => {
  const [files, setFiles] = useState();
  useEffect(() => {
    axios
      .post("/api/load_logo", { dir: "2-Color" })
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (files === undefined) return <div />;
  return (
    <>
      {files.map((file, index) => {
        return (
          <div className="col-md-6 col-sm-6" key={index}>
            <div className="temrows mat-lft-repeat-blk"
                 style={{
                   border: '2px solid #34495e',
                   background: '#5c6b7b',
                   borderRadius: '15px',
                   padding: '10px',
                 }}
            >
              <img
                src={require(`./../../../../assets/logos/2-Color/${file}`)}
                alt="Template"
                className="img-responsive"
                onClick={props.addNewArtWork}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const ThreeColorLogos = (props) => {
  const [files, setFiles] = useState();
  useEffect(() => {
    axios
      .post("/api/load_logo", { dir: "3-Color" })
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (files === undefined) return <div />;
  return (
    <>
      {files.map((file, index) => {
        return (
          <div className="col-md-6 col-sm-6" key={index}>
            <div className="temrows mat-lft-repeat-blk"
                 style={{
                   border: '2px solid #34495e',
                   background: '#5c6b7b',
                   borderRadius: '15px',
                   padding: '10px',
                 }}
            >
              <img
                src={require(`./../../../../assets/logos/3-Color/${file}`)}
                alt="Template"
                className="img-responsive"
                onClick={props.addNewArtWork}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const FourColorLogos = (props) => {
  const [files, setFiles] = useState();
  useEffect(() => {
    axios
      .post("/api/load_logo", { dir: "4-Color" })
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (files === undefined) return <div />;
  return (
    <>
      {files.map((file, index) => {
        return (
          <div className="col-md-6 col-sm-6" key={index}>
            <div className="temrows mat-lft-repeat-blk"
                 style={{
                   border: '2px solid #34495e',
                   background: '#5c6b7b',
                   borderRadius: '15px',
                   padding: '10px',
                 }}
            >
              <img
                src={require(`./../../../../assets/logos/4-Color/${file}`)}
                alt="Template"
                className="img-responsive"
                onClick={props.addNewArtWork}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const PracticeCircles = (props) => {
  const [files, setFiles] = useState();
  useEffect(() => {
    axios
      .post("/api/load_logo", { dir: "Practice Circles" })
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (files === undefined) return <div />;
  return (
    <>
      {files.map((file, index) => {
        return (
          <div className="col-md-6 col-sm-6" key={index}>
            <div className="temrows mat-lft-repeat-blk"
                 style={{
                   border: '2px solid #34495e',
                   background: '#5c6b7b',
                   borderRadius: '15px',
                   padding: '10px',
                 }}
            >
              <img
                src={require(`./../../../../assets/logos/Practice Circles/${file}`)}
                alt="Template"
                className="img-responsive"
                onClick={props.addNewArtWork}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

class ArtworkToolbar extends React.Component {
  state = {
    files: [],
    logoType: "1",
  };

  componentDidMount = () => {
    axios
      .post("/api/artwork_images")
      .then((res) => {
        this.setState({ files: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addNewArtWork = (e) => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject) return;
    let { src } = e.target;
    this.props.setImageUrl(src);
    this.props.setComponentType("Artwork");
  };

  addNewLogo = (e) => {
    let { src } = e.target;
    this.props.setImageUrl(src);
    this.props.setComponentType("Image");
  };

  bindFileExplorer = (e) => {
    // e.preventDefault()
    $("#customFile").click();
  };


  onChangeHandler = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const files = e.target.files;
    const logos = [];
    let i = 0;

    reader.onload = (re) => {
      var img = new Image();
      img.src = re.target.result;
      logos.push({
        filename: files[i - 1].name,
        href: re.target.result,
      });
      img.onload = () => {
        axios
            .post("/api/upload_image", {src:img.src})
            .then((res) => {
              let url = res.data.filename;
              var fabImage = new window.fabric.Image(img);
              fabImage.set({
                left: 0,
                top: 0,
                hoverCursor: "default",
                source:url,
              });
              if (img.width > img.height) {
                fabImage.scaleToWidth(this.props.canvas.getWidth() / 1.25);
              } else {
                fabImage.scaleToHeight(this.props.canvas.getHeight() / 1.25);
              }
              this.props.canvas.add(fabImage);

              if (i < files.length) {
                reader.readAsDataURL(files[i++]);
              } else {
                this.props.canvas.renderAll();
                this.props.setUploadLogos(logos);
              }
            })
            .catch((err) => {
              console.log(err);
              alert("An unexpected error occurred. Please contact Admin");
            });

        return;
      };
    };
    reader.readAsDataURL(files[i++]);
  };


  selectLogo = (e) => {
    const { value } = e.target;
    this.setState({ logoType: value });
  };

  render() {
    const files = this.state.files;
    const logos = [
      "One Color Logos",
      "Two Color Logos",
      "Three Color Logos",
      "Four Color Logos",
      "Practice Circles",
    ];

    return (
      <>
        <div className="adddiv">
          <h2>Select Artwork</h2>
        </div>
        <div className="templatesmain mat-lft-blk-menu">
          <div className="row ">
            <div className="col-md-12">
              <div className="form-group">
                <div className="choos-clr-label">Select from library</div>
                <select
                  className="form-control clipart-selct slct-font-fam choose-your-size-drop"
                  defaultValue="Popoluar Clipart"
                  onChange={this.selectLogo}
                >
                  {" "}
                  {[
                    logos.map((logo, i) => (
                      <option key={logo} value={i + 1}>
                        {logo}
                      </option>
                    )),
                  ]}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="adddiv">
                <p
                  onClick={() => this.bindFileExplorer()}
                  style={{ cursor: "pointer" }}
                >
                  Upload your own images from computer
                </p>
                <input
                  type="file"
                  multiple
                  className="custom-file-input"
                  id="customFile"
                  name="files"
                  onChange={(e) => this.onChangeHandler(e)}
                />
                <small>
                  <i>Accepted files : SVG, JPG, JPEG, PNG</i>
                </small>
              </div>
            </div>
          </div>
          <div className="row ">
            {this.state.logoType === "0" ? <div /> : <div />}
            {this.state.logoType === "1" ? (
              <OneColorLogos addNewArtWork={this.addNewArtWork} />
            ) : (
              <div />
            )}
            {this.state.logoType === "2" ? (
              <TwoColorLogos addNewArtWork={this.addNewArtWork} />
            ) : (
              <div />
            )}
            {this.state.logoType === "3" ? (
              <ThreeColorLogos addNewArtWork={this.addNewArtWork} />
            ) : (
              <div />
            )}
            {this.state.logoType === "4" ? (
              <FourColorLogos addNewArtWork={this.addNewArtWork} />
            ) : (
              <div />
            )}
            {this.state.logoType === "5" ? (
              <PracticeCircles addNewArtWork={this.addNewArtWork} />
            ) : (
              <div />
            )}
          </div>
          <div className="row ">
            <ImageList files={files} addNewArtWork={this.addNewLogo} />
          </div>
        </div>
      </>
    );
  }
}

const setImageUrl = (url) => {
  return {
    type: "SET_IMAGE_URL",
    image_url: url,
  };
};

const setComponentType = (type) => {
  return {
    type: "SET_COMPONENT_TYPE",
    component_type: type,
  };
};

const setUploadLogos = (logos) => {
  return {
    type: "SET_UPLOADED_LOGOS",
    logos,
  };
};

const mapStateToProps = (state) => {
  return {
    canvas: state.canvas,
  };
};

const mapDispatchToPropsForRect = (dispatch) => {
  return bindActionCreators(
    { setComponentType, setImageUrl, setUploadLogos },
    dispatch
  );
};

const ArtworkToolbarConnect = connect(
  mapStateToProps,
  mapDispatchToPropsForRect
)(ArtworkToolbar);

class ArtworkToolSidebar extends React.Component {
  closeNav = () => {
    document.getElementById("mySidenav5").style.width = "0";
  };

  render() {
    return (
      <>
        <div
          id="artwrk"
          className="h100 second-menu fordeskview"
          style={{ display: "none" }}
        >
          <ArtworkToolbarConnect />
        </div>
        <div
          className="text-center h100 pad0 mtopneg sidenav5 formobview wo"
          id="mySidenav5"
        >
          <a
            style={{ color: "#fff" }}
            href="#"
            className="closebtn"
            onClick={() => this.closeNav()}
          >
            &times;
          </a>
          <ArtworkToolbarConnect />
        </div>
      </>
    );
  }
}

export default ArtworkToolSidebar;
