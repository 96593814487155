import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import { VinColorHex,VinColorName} from "../../ColorCodeHelper";

const smoothColors = [VinColorName.White, VinColorName.LightGrey, VinColorName.Gold, VinColorName.Orange, 
                      VinColorName.Red, VinColorName.DarkGreen, VinColorName.Maroon, VinColorName.Brown, 
                      VinColorName.Black, VinColorName.Purple, VinColorName.CharcoalGrey, VinColorName.Navy, VinColorName.RoyalBlue];

const tatamiColors = [VinColorName.RoyalBlue, VinColorName.Red, VinColorName.Gold, VinColorName.Black,
                      VinColorName.LightGreen, VinColorName.White, VinColorName.CharcoalGrey,VinColorName.LightGrey];

const carpetColors = [VinColorName.Red, VinColorName.Navy, VinColorName.Purple, VinColorName.Green,
                      VinColorName.LightGrey, VinColorName.CharcoalGrey, VinColorName.Black, VinColorName.Teal];

class MatsToolbar extends React.Component {

  setActiveTab = id => {
    $('.flexi-colors div.active').removeClass('active')
    $(`.${id}-color`).addClass('active')
    $('.smooth-colors').css('display', 'none')
    $('.tatami-colors').css('display', 'none')
    $('.carpet-colors').css('display', 'none')
    $(`.${id}-colors`).css('display', 'block')
    this.props.setFlexiType(id);
  }

  setSmoothBackgroundColor = index => {
    this.props.setFlexiSmoothIndex(index)
    let objects = this.props.canvas.getObjects()
    console.log(objects);
    objects.forEach(item=>{
      if(item.type === 'rect'){
        item.set('visible', true)
        item.setGradient('fill', {
          type: 'linear',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: item.height,
          colorStops: {
            0: this.props.flexi_smooth_colors[index][0],
            0.5: this.props.flexi_smooth_colors[index][1],
            1: this.props.flexi_smooth_colors[index][1],
          },
        })
      }
    })
    this.props.setFlexiColor(smoothColors[index]);

    this.props.canvas.renderAll()
    this.updateCanvasState()
  }

  setRoseWoodBackground = () => {
    let objects = this.props.canvas.getObjects()
    this.props.setFlexiColor('Rose Wood');
    objects.forEach(item=>{
      if(item.type === 'rect'){
        item.set('visible', false);
      }
    })

    window.fabric.Image.fromURL(
      require('./../../../../assets/img/rosewood.png'),
      image => {
        this.props.canvas.setBackgroundImage(
          image,
          this.props.canvas.renderAll.bind(this.props.canvas),
          {
            top: 0,
            left: 0,
            width: 400,
            height: 480,
          },
        )
      },
    )
    this.props.canvas.renderAll()
  }

  setSandalWoodBackground = () => {
    let objects = this.props.canvas.getObjects()
    this.props.setFlexiColor('Sand Wood');
    objects.forEach(item=>{
      if(item.type === 'rect'){
        item.set('visible', false);
      }
    })

    window.fabric.Image.fromURL(
      require('./../../../../assets/img/sandalwood.png'),
      image => {
        this.props.canvas.setBackgroundImage(
          image,
          this.props.canvas.renderAll.bind(this.props.canvas),
          {
            top: 0,
            left: 0,
            width: 400,
            height: 480,
          },
        )
      },
    )
    this.props.canvas.renderAll()
  }

  setTatamiBackgroundColor = index => {
    this.props.setFlexiTatamiIndex(index)
    let objects = this.props.canvas.getObjects();
    console.log(tatamiColors[index]);
    this.props.setFlexiColor(tatamiColors[index]);
    objects.forEach(item=>{
      if(item.type === 'rect'){
        item.set('visible', true)
        item.setGradient('fill', {
          type: 'linear',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: item.height,
          colorStops: {
            0: this.props.flexi_tatami_colors[index][0],
            0.5: this.props.flexi_tatami_colors[index][1],
            1: this.props.flexi_tatami_colors[index][1],
          },
        })
      }
    })

    this.props.canvas.renderAll()
    this.updateCanvasState()
  }

  setCarpetBackgroundColor = index => {
    this.props.setFlexiSmoothIndex(index)
    let objects = this.props.canvas.getObjects()
    this.props.setFlexiColor(carpetColors[index]);
    objects.forEach(item=>{
      if(item.type === 'rect'){
        item.set('visible', true)
        item.setGradient('fill', {
          type: 'linear',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: item.height,
          colorStops: {
            0: this.props.flexi_carpet_colors[index][0],
            0.5: this.props.flexi_carpet_colors[index][1],
            1: this.props.flexi_carpet_colors[index][1],
          },
        })
      }
    })

    this.props.canvas.renderAll()
    this.updateCanvasState()
  }

  setThickness = (e) => {
    if (e.target.value) {
      this.props.setThickness(e.target.value);
    }
  }

  updateCanvasState = () => {
    let tempConfig = this.props._config
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON()
      let canvasAsJson = JSON.stringify(jsonData)
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson

        let numberOfElementsToRetain = indexToBeInserted + 1
        tempConfig.canvasState = tempConfig.canvasState.splice(
          0,
          numberOfElementsToRetain,
        )
      } else {
        tempConfig.canvasState.push(canvasAsJson)
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1

      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = 'disabled'
      }
    }
    this.props.setCanvasConfig(tempConfig)
  }

  render() {
    const {
      mat_style_flexi_conenct,
      mat_style_flexi_roll_mats,
      mat_style_non_flexi_roll_mats
    } = this.props;
    return (
      <>
        <div className="adddiv">
          <h2>Mats</h2>
        </div>
        <div className="templatesmain">
          <div className="col-md-12 mt-15 row-border-btm pb-15">
            <div className="col-md-1"></div>
            <div className="col-md-10 flexi-colors">
              <div
                className="col-md-4 smooth-color active"
                data-target="smooth"
                onClick={() => this.setActiveTab('smooth')}
              >
                <span onClick={() => this.setActiveTab('smooth')}>SMOOTH</span>
              </div>
              <div
                className="col-md-4 tatami-color"
                data-target="tatami"
                onClick={() => this.setActiveTab('tatami')}
              >
                <span onClick={() => this.setActiveTab('tatami')}>TATAMI</span>
              </div>
              <div
                className="col-md-4 carpet-color"
                data-target="carpet"
                onClick={() => this.setActiveTab('carpet')}
              >
                <span onClick={() => this.setActiveTab('carpet')}>CARPET</span>
              </div>
              <div className="smooth-colors">
                <div
                  className="preset-color-row row"
                  style={{ paddingTop: '8px' }}
                >
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(0)}
                    style={{ background: VinColorHex.White }}
                  >
                    <span className="tooltiptext">{ VinColorName.White }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(1)}
                    style={{ background: VinColorHex.LightGrey }}
                  >
                    <span className="tooltiptext">{ VinColorName.LightGrey }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(2)}
                    style={{ background: VinColorHex.Gold }}
                  >
                    <span className="tooltiptext">{ VinColorName.Gold }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(3)}
                    style={{ background: VinColorHex.Orange }}
                  >
                    <span className="tooltiptext">{ VinColorName.Orange }</span>
                  </div>
                </div>
                <div className="preset-color-row row">
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(4)}
                      style={{ background: VinColorHex.Red }}
                  >
                    <span className="tooltiptext">{ VinColorName.Red }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(5)}
                    style={{ background: VinColorHex.DarkGreen }}
                  >
                    <span className="tooltiptext">{ VinColorName.DarkGreen }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(6)}
                    style={{ background: VinColorHex.Maroon }}
                  >
                    <span className="tooltiptext">{ VinColorName.Maroon }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setSmoothBackgroundColor(7)}
                    style={{ background: VinColorHex.Brown }}
                  >
                    <span className="tooltiptext">{ VinColorName.Brown }</span>
                  </div>
                </div>
                <div
                    className="preset-color-row row"
                    style={{ paddingBottom: '8px' }}
                >
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(8)}
                      style={{ background: VinColorHex.Black }}
                  >
                    <span className="tooltiptext">{ VinColorName.Black }</span>
                  </div>
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(9)}
                      style={{ background: VinColorHex.Purple }}
                  >
                    <span className="tooltiptext">{ VinColorName.Purple }</span>
                  </div>
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(10)}
                      style={{ background: VinColorHex.CharcoalGrey }}
                  >
                    <span className="tooltiptext">{ VinColorName.CharcoalGrey }</span>
                  </div>
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(11)}
                      style={{ background: VinColorHex.Navy }}
                  >
                    <span className="tooltiptext">{ VinColorName.Navy }</span>
                  </div>

                </div>
                <div
                  className="preset-color-row row"
                  style={{ paddingBottom: '8px' }}
                >
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(12)}
                      style={{ background: VinColorHex.RoyalBlue }}
                  >
                    <span className="tooltiptext">{ VinColorName.RoyalBlue }</span>
                  </div>
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setSmoothBackgroundColor(13)}
                      style={{ background: VinColorHex.CarolinaBlue }}
                  >
                    <span className="tooltiptext">{ VinColorName.CarolinaBlue }</span>
                  </div>
                  <div
                      className="pre-clr-box clr-pr1 rosewood tooltip"
                      onClick={() => this.setRoseWoodBackground()}
                  >
                    <span className="tooltiptext">Rose Wood</span>
                  </div>
                  <div
                      className="pre-clr-box clr-pr1 sandalwood tooltip"
                      onClick={() => this.setSandalWoodBackground()}
                  >
                    <span className="tooltiptext">Sand Wood</span>
                  </div>
                </div>
              </div>

              <div className="tatami-colors">
                <div
                  className="preset-color-row row"
                  style={{ paddingTop: '8px' }}
                >
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(0)}
                    style={{ background: VinColorHex.RoyalBlue }}
                  >
                    <span className="tooltiptext">{ VinColorName.RoyalBlue }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(1)}
                    style={{ background: VinColorHex.Red }}
                  >
                    <span className="tooltiptext">{VinColorName.Red }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(2)}
                    style={{ background: VinColorHex.Gold }}
                  >
                    <span className="tooltiptext">{ VinColorName.Gold }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(3)}
                    style={{ background: VinColorHex.Black }}
                  >
                    <span className="tooltiptext">{ VinColorName.Black }</span>
                  </div>
                </div>
                <div
                  className="preset-color-row row"
                  style={{ paddingBottom: '8px' }}
                >
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setTatamiBackgroundColor(4)}
                      style={{ background: VinColorHex.LightGreen }}
                  >
                    <span className="tooltiptext">{ VinColorName.LightGreen }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(5)}
                    style={{ background: VinColorHex.White }}
                  >
                    <span className="tooltiptext">{ VinColorName.White }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(6)}
                    style={{ background: VinColorHex.CharcoalGrey }}
                  >
                    <span className="tooltiptext">{ VinColorName.CharcoalGrey }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setTatamiBackgroundColor(7)}
                    style={{ background: VinColorHex.LightGrey }}
                  >
                    <span className="tooltiptext">{ VinColorName.LightGrey }</span>
                  </div>
                </div>
              </div>
              <div className="carpet-colors">
                <div
                  className="preset-color-row row"
                  style={{ paddingTop: '8px' }}
                >
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(0)}
                    style={{ background: VinColorHex.Red }}
                  >
                    <span className="tooltiptext">{ VinColorName.Red }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(1)}
                    style={{ background: VinColorHex.Navy }}
                  >
                    <span className="tooltiptext">{ VinColorName.Navy }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(2)}
                    style={{ background: VinColorHex.Purple }}
                  >
                    <span className="tooltiptext">{ VinColorName.Purple }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(3)}
                    style={{ background: VinColorHex.Green }}
                  >
                    <span className="tooltiptext">{ VinColorName.Green }</span>
                  </div>
                </div>
                <div
                  className="preset-color-row row"
                  style={{ paddingBottom: '8px' }}
                >
                  <div
                      className="pre-clr-box clr-pr1 tooltip"
                      onClick={() => this.setCarpetBackgroundColor(4)}
                      style={{ background: VinColorHex.LightGrey }}
                  >
                    <span className="tooltiptext">{ VinColorName.LightGrey }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(5)}
                    style={{ background: VinColorHex.CharcoalGrey }}
                  >
                    <span className="tooltiptext">{ VinColorName.CharcoalGrey }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(6)}
                    style={{ background: VinColorHex.Black }}
                  >
                    <span className="tooltiptext">{ VinColorName.Black }</span>
                  </div>
                  <div
                    className="pre-clr-box clr-pr1 tooltip"
                    onClick={() => this.setCarpetBackgroundColor(8)}
                    style={{ background: VinColorHex.Teal }}
                  >
                    <span className="tooltiptext">{ VinColorName.Teal }</span>
                  </div>
                </div>
              </div>
              {/*<div className="foam-thickness col-md-12">
                <div
                  className="thickness col-md-12"
                  style={{
                    background: '#2a3f54',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingTop: '15px',
                  }}
                >
                  <span
                    className="col-md-6"
                    style={{
                      color: 'white',
                      float: 'left',
                      paddingLeft: '0px',
                      paddingTop: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Foam Thickness
                  </span>
                  <select
                    id="foamThickness"
                    className="form-control slct-font-fam"
                    onChange={this.setThickness}
                    value={this.props.thickness}
                    style={{ width: '30%', float: ' right' }}
                  >
                    <option value='1"'>1"</option>
                    <option value='1 1/4"'>1 1/4"</option>
                    <option value='1 1/2"'>1 1/2"</option>
                    <option value='1 5/8"'>1 5/8"</option>
                    <option value='2"'>2"</option>
                  </select>
                </div>
              </div>
              <div className="mats-style row">
                <span className="col-md-12">MATS STYLE</span>

                <div className="flexi-connect col-md-12">
                  <span className="col-md-8">FLEXI-Connect</span>
                  <input type="checkbox" name="col-md-4 mat-style" />
                </div>
                <div className="flexi-roll-mats col-md-12">
                  <span className="col-md-6">FLEXI-Roll Mats</span>
                  <input type="checkbox" name="mat-style" />
                </div>
                <div className="non-flexi-roll-mats col-md-12">
                  <span className="col-md-6">Non-FLEXI-Roll Mats</span>
                  <input type="checkbox" name="mat-style" className="" />
                </div>
              </div>*/}
            </div>
          </div>
          <div className="col-md-12 shape-blk">
            <div className="choos-clr-label"><span>MAT STYLE</span></div>
          </div>
          <div className="add-text-blk-hmenu">
            <div className="font-select">
              <div className="row row-border-btm pt-15 pb-15">
                <div className="col-md-12">
                  <div className="size-blk pt-10">
                    <div className="size-label">
                      <span>Foam Thickness</span>
                    </div>
                    <div className="size-slider">

                    </div>
                    <div className="size-value">
                      <select
                          id="foamThickness"
                          className="form-control slct-font-fam"
                          onChange={this.setThickness}
                          value={this.props.thickness}
                          style={{float: ' right' }}
                      >
                        <option value='1"'>1"</option>
                        <option value='1 1/4"'>1 1/4"</option>
                        <option value='1 1/2"'>1 1/2"</option>
                        <option value='1 5/8"'>1 5/8"</option>
                        <option value='2"'>2"</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-border-btm pt-15 pb-15">

                <div className="col-md-12">
                  <div className="size-blk pt-10" style={{justifyContent:'space-between'}}>
                    <div className="size-label">
                      <span>FLEXI-Connect</span>
                    </div>
                    <div className="size-value">
                      <input className='flexi-connect' checked={mat_style_flexi_conenct} type="checkbox" onChange={()=>{this.props.setStyleFlexiConnect(!mat_style_flexi_conenct)}} name="mat-style" />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="size-blk pt-10" style={{justifyContent:'space-between'}}>
                    <div className="size-label">
                      <span>FLEXI-Roll Mats</span>
                    </div>
                    <div className="size-value">
                      <input className='flexi-roll-mats' checked={mat_style_flexi_roll_mats} type="checkbox" onChange={()=>{this.props.setStyleFlexiRollMats(!mat_style_flexi_roll_mats)}} name="mat-style" />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="size-blk pt-10" style={{justifyContent:'space-between'}}>
                    <div className="size-label">
                      <span>Non-FLEXI-Roll Mats</span>
                    </div>
                    <div className="size-value">
                      <input className='non-flexi-roll-mats' checked={mat_style_non_flexi_roll_mats} onChange={()=>{this.props.setStyleNonFlexiRollMats(!mat_style_non_flexi_roll_mats)}} type="checkbox" name="mat-style" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const setWrestlingConfig = (width, height, isNewMaterial) => {
  const data = {
    width: width,
    height: height,
    length_unit: 'METER',
    isNewMaterial: isNewMaterial,
  }
  return {
    type: 'SET_WRESTLING_CONFIG',
    _config: data,
  }
}

const setCanvasConfig = _config => {
  return {
    type: 'SET_CONFIG',
    _config,
  }
}

const setStyleFlexiConnect = value =>{
  return {
    type: 'SET_STYLE_FLEXI_CONNECT',
    value,
  }
}
const setStyleFlexiRollMats = value =>{
  return {
    type: 'SET_STYLE_FLEXI_ROLL_MATS',
    value,
  }
}
const setStyleNonFlexiRollMats = value =>{
  return {
    type: 'SET_STYLE_NON_FLEXI_ROLL_MATS',
    value,
  }
}

const setComponentType = type => {
  return {
    type: 'SET_COMPONENT_TYPE',
    component_type: type,
  }
}

const setFlexiSmoothIndex = index => {
  return {
    type: 'SET_FLEXI_SMOOTH_COLOR_INDEX',
    index: index,
  }
}

const setFlexiTatamiIndex = index => {
  return {
    type: 'SET_FLEXI_TATAMI_COLOR_INDEX',
    index: index,
  }
}

const setFlexiCarpetIndex = index => {
  return {
    type: 'SET_FLEXI_CARPET_COLOR_INDEX',
    index: index,
  }
}

const setFlexiColor = color => {
  return {
    type: 'SET_FLEXI_COLOR',
    color
  }
}

const setMaterialChanged = changed => {
  return {
    type: 'SET_FLEXI_MATERIAL_CHANGED',
    changed: changed,
  }
}

const setFlexiType = flexi_type => {
  return {
    type: 'SET_FLEXI_TYPE',
    flexi_type
  }
}

const setThickness = thickness => {
  return {
    type: 'SET_THICKNESS',
    thickness
  }
}

const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    _config: state._config,
    flexi_smooth_colors: state.flexi_smooth_colors,
    flexi_tatami_colors: state.flexi_tatami_colors,
    flexi_carpet_colors: state.flexi_carpet_colors,
    thickness: state.thickness,
    flexi_config: state.flexi_config,
    flexi_color: state.flexi_color,
    mat_style_flexi_conenct:state.mat_style_flexi_conenct,
    mat_style_flexi_roll_mats:state.mat_style_flexi_roll_mats,
    mat_style_non_flexi_roll_mats:state.mat_style_non_flexi_roll_mats,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setWrestlingConfig,
      setComponentType,
      setFlexiSmoothIndex,
      setFlexiTatamiIndex,
      setFlexiCarpetIndex,
      setCanvasConfig,
      setMaterialChanged,
      setFlexiType,
      setThickness,
      setFlexiColor,
      setStyleFlexiConnect,
      setStyleFlexiRollMats,
      setStyleNonFlexiRollMats
    },
    dispatch,
  )
}

const MatsToolbarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatsToolbar)

class MatsToolSidebar extends React.Component {
  closeNav = () => {
    document.getElementById('mySidenav1').style.width = '0'
  }

  render() {
    return (
      <>
        <div id="mats" className="h100 second-menu fordeskview">
          <MatsToolbarConnect />
        </div>
        <div
          className="text-center h100 pad0 mtopneg sidenav1 formobview wo"
          id="mySidenav1"
        >
          <a
            style={{ color: '#fff' }}
            href="#"
            className="closebtn"
            onClick={() => this.closeNav()}
          >
            &times;
          </a>
          <MatsToolbarConnect />
        </div>
      </>
    )
  }
}

export default MatsToolSidebar
