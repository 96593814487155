import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import axios from "axios";

const ImageList = props => {
  const files = props.files;
  if (files.length <= 0) return <div />;
  else
    return files.map((file, idx) => (
      <div className="col-md-4" key={`div_${idx}`}>
        <img
          src={require(`./../../../../../icons/${file}`)}
          alt=""
          key={idx}
          onClick={props.addNewLogo}
        />
      </div>
    ));
};
class LogoToolbar extends React.Component {
  state = {
    files: []
  };

  componentDidMount = () => {
    axios
      .post("/api/logo_images")
      .then(res => {
        // this.setState({ files: res.data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  bindFileExplorer = () => {
    $("#customFile").click();
  };

  onChangeHandler = e => {
    e.preventDefault()
    const reader = new FileReader();
    const files = e.target.files;
    let i = 0;

    reader.onload = re => {
      var wallpad_config = this.props.wallpad_config.config;
      var current_wallpad = this.props.wallpad_config.current_wallpad;
      var logos = wallpad_config[current_wallpad] && wallpad_config[current_wallpad].logos ? wallpad_config[current_wallpad].logos : []

      this.props.setCurrentWallPad(
        this.props.wallpad_config.current_wallpad,
        {
          logos: [
            ...logos, {
              filename: files[i-1].name,
              href: re.target.result
            }
          ]
        }
      )

      var img = new Image();
      img.src = re.target.result;
      img.onload = () => {
          axios
              .post("/api/upload_image", {src:img.src})
              .then((res) => {
                  let url = res.data.filename;
                  var fabImage = new window.fabric.Image(img);
                  fabImage.set({
                      left: 0,
                      top: 0,
                      hoverCursor: "default",
                      source:url,
                  });
                  if (img.width > img.height) {
                      fabImage.scaleToWidth(this.props.canvas.getWidth() / 1.5);
                  } else {
                      fabImage.scaleToHeight(this.props.canvas.getHeight() / 1.5);
                  }
                  this.props.canvas.add(fabImage);

                  if (i < files.length) {
                      reader.readAsDataURL(files[i++])
                  } else {
                      this.props.canvas.renderAll();
                  }
              })
              .catch((err) => {
                  console.log(err);
                  alert("An unexpected error occurred. Please contact Admin");
              });
      }
    }

    reader.readAsDataURL(files[i++])
  };

  addNewLogo = e => {
    let { src } = e.target;
    this.props.setImageUrl(src);
    this.props.setComponentType("Image");
  };

  render() {
    const files = this.state.files;
    return (
      <>
        <div className="row adddiv">
          <h2>Add Logo</h2>
          <p onClick={() => this.bindFileExplorer()}>
            Upload your own images from computer
          </p>
          <input
            type="file"
            multiple
            className="custom-file-input"
            id="customFile"
            name="files"
            onChange={this.onChangeHandler}
          />
          <small>
            <i>Accepted Files : SVG, JPG, JPEG, PNG</i>
          </small>
        </div>
        <div className="row image_list">
          <ImageList files={files} addNewLogo={this.addNewLogo} />
        </div>
      </>
    );
  }
}

const setImageUrl = url => {
  return {
    type: "SET_IMAGE_URL",
    image_url: url
  };
};

const setComponentType = type => {
  return {
    type: "SET_COMPONENT_TYPE",
    component_type: type
  };
};

const setCurrentWallPad = (index, config) => {
  return {
    type: 'SET_CURRENT_WALLPAD',
    index,
    config
  }
}

const mapStateToProps = state => {
  return {
    canvas: state.canvas,
    wallpad_config: state.wallpad_config,
  };
};

const mapDispatchToPropsForLogo = dispatch => {
  return bindActionCreators({ setImageUrl, setComponentType, setCurrentWallPad }, dispatch);
};

const LogoToolbarConnect = connect(
  mapStateToProps,
  mapDispatchToPropsForLogo
)(LogoToolbar);

class LogoToolSidebar extends React.Component {
  closeNav4 = () => {
    document.getElementById("mySidenav3").style.width = "0";
  };

  render() {
    return (
      <>
        <div
          id="logo"
          className="h100 second-menu fordeskview"
          style={{ display: "none" }}
        >
          <LogoToolbarConnect />
        </div>
        <div
          className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
          id="mySidenav3"
        >
          <a
            style={{ color: "#fff" }}
            href="#"
            className="closebtn"
            onClick={() => this.closeNav4()}
          >
            &times;
          </a>
          <LogoToolbarConnect />
        </div>
      </>
    );
  }
}

export default LogoToolSidebar;
