import React from "react";
import axios from "axios";
import $ from "jquery";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const ImageList = props => {
	const files = props.files;
	if (files.length <= 0) return <div/>;
	else
		return files.map((file, idx) => (
			<div className="col-md-4" key={`div_${idx}`}>
				<img
					src={require(`./../../../../../icons/${file}`)}
					alt=""
					key={idx}
					onClick={props.addNewLogo}
				/>
			</div>
		));
};

class LogoToolbar extends React.Component {
	constructor() {
		super();
		this.state = {
			files: []
		};
	}

	componentDidMount = () => {
		/*axios
			.post("/api/logo_images")
			.then(res => {
				this.setState({ files: res.data });
			})
			.catch(err => {
				console.log(err);
			});*/
	};

	bindFileExplorer = () => {
		$("#customFile").click();
	};

	updateCanvasState (){
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	onChangeHandler = e => {
		e.preventDefault()
		const reader = new FileReader();
		const files = e.target.files;
		const logos = [];
		let i = 0;

		reader.onload = re => {
			var img = new Image();
			img.src = re.target.result;
			logos.push({
				filename: files[i - 1].name,
				href: re.target.result
			});
			axios
				.post("/api/upload_image", {src:img.src})
				.then((res) => {
					let url = res.data.filename;
					var fabImage = new window.fabric.Image(img);
					fabImage.set({
						left: 0,
						top: 0,
						hoverCursor: "default",
						source:url,
					});
					if (img.width > img.height) {
						fabImage.scaleToWidth(this.props.canvas.getWidth() / 1.5);
					} else {
						fabImage.scaleToHeight(this.props.canvas.getHeight() / 1.5);
					}
					this.props.canvas.add(fabImage);

					if (i < files.length) {
						reader.readAsDataURL(files[i++])
					} else {
						this.props.canvas.renderAll();
						this.props.setUploadLogos(logos);
					}
				})
				.catch((err) => {
					console.log(err);
					alert("An unexpected error occurred. Please contact Admin");
				});
		}

		reader.readAsDataURL(files[i++])
	};

	addNewLogo = e => {
		let {src} = e.target;
		this.props.setImageUrl(src);
		window.fabric.Image.fromURL(
			require("./../../../../assets/img/profile.jpg"),
			image => {
				image.scale(0.5);
				image.set({
					left: 0,
					top: 0,
					hoverCursor: "default"
				});

				this.props.addNewImage(image);
			}
		);
	};

	render() {
		const files = this.state.files;
		return (
			<>
				<div className="row adddiv">
					<h2>Add Logo</h2>
				</div>
				<div className="row adddiv">
					<p
						onClick={() => this.bindFileExplorer()}
						style={{cursor: "pointer"}}
					>
						Upload your own images from computer
					</p>
					<input
						type="file"
						multiple
						className="custom-file-input"
						id="customFile"
						name="files"
						onChange={this.onChangeHandler}
					/>
					<small>
						<i>Accepted Files : SVG, JPG, JPEG, PNG</i>
					</small>
				</div>
				<div className="row image_list">
					<ImageList files={files} addNewLogo={this.addNewLogo}/>
				</div>
			</>
		);
	}
}

const setImageUrl = url => {
	return {
		type: "SET_IMAGE_URL",
		image_url: url
	};
};

const setUploadLogos = logos => {
	return {
		type: "SET_UPLOADED_LOGOS",
		logos
	};
};

const addNewImage = image => {
	return {
		type: "NEW_IMAGE",
		component: image
	};
};

const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}

const mapDispatchToPropsForLogo = dispatch => {
	return bindActionCreators({setImageUrl, addNewImage, setUploadLogos,setCanvasConfig}, dispatch);
};

const mapStateToProps = state => {
	return {
		components: [...state.components],
		_config: state._config,
		canvas: state.canvas,
		canvas_grid: state.canvas_grid,
		templates: state.templates,
		logo_url: state.logo_url,
		templates_width: state.templates_width,
		swain_config: state.swain_config,
		zoom: state.zoom,
		selectedMaterialColor: state.selectedMaterialColor
	};
};

const LogoToolbarConnect = connect(
	mapStateToProps,
	mapDispatchToPropsForLogo
)(LogoToolbar);

class LogoToolSidebar extends React.Component {
	closeNav4 = () => {
		document.getElementById("mySidenav4").style.width = "0";
	};

	render() {
		return (
			<>
				<div
					id="logo"
					className="h100 second-menu fordeskview"
					style={{display: "none"}}
				>
					<LogoToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav4"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav4()}
					>
						&times;
					</a>
					<LogoToolbarConnect/>
				</div>
			</>
		);
	}
}

export default LogoToolSidebar