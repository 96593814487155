import React from "react";
import TextColorPaneConnect from "../../../TextColorPane";
import CustomColorPane from "../../../customColorPane";
import {TurfColorHex,TurfColorName} from "./../../ColorCodeHelper"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


class TurfShapesToolbar extends React.Component {
	constructor() {
		super();
		this.state ={};
	}

	addShape(type) {
		switch (type) {
			case 1:
				this.addSingleStar();
				return;
			case 2:
				this.addDoubleStar();
				return;
			case 3:
				this.addSingleStairs();
				return;
			case 4:
				this.addDoubleStairs();
				return;
			case 5:
				this.addCircle();
				return;
			case 6:
				this.addRectangle();
				return;
		}
	}
	addRectangle(){
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let canvas = this.props.canvas;
		let width = (canvas.height/canvas.getZoom())/2;
		let height = (canvas.height/canvas.getZoom())/2;
		let fill = '#f62424',
			stroke = '#096ed4',
			strokeWidth = grid*0.166667;

		let rect = new window.fabric.Rect({
			stroke,
			strokeWidth,
			top:0,
			left:0,
			fill,
			width,
			height
		});
		this.props.canvas.add(rect);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}
	addCircle(){
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let canvas = this.props.canvas;
		let radius = (canvas.height/canvas.getZoom())/4;
		let fill = '#f62424',
			stroke = '#096ed4',
			strokeWidth = grid*0.166667;

		let circle = new window.fabric.Circle({
			stroke,
			strokeWidth,
			top:0,
			left:0,
			fill,
			radius,
		});

		this.props.canvas.add(circle);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}

	addSingleStairs() {
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let width = grid*1.5;
		let height = grid*1.5;
		let horizontalGap = 3;
		let verticalGap = 5;
		let startGap = 0.166667;

		let fill = '#f62424',
			stroke = '#096ed4',
			strokeWidth = grid*0.166667;

		let allRect = [];
		for(let i=0 ; i<10;i++) {
			let left =0;
			if(allRect.length >0){
				let oldRect = allRect[allRect.length-1];
				left = oldRect.left+oldRect.width;
			}
			let newRect = new window.fabric.Rect({
				stroke,
				strokeWidth,
				top:0,
				left,
				fill,
				width,height
			});
			allRect.push(newRect)
		}
		let group = new window.fabric.Group([...allRect],
			{top:0,left:0,name:'single-stairs'});
		let newGridLeft = Math.abs(parseInt(Math.round(group.left / grid / 3 )/5))*5+2.5;
		newGridLeft = newGridLeft * grid * 3;
		group.set({
			left: ( newGridLeft - (group.width*group.scaleX)/2 ) ,
			// left: (Math.round(group.left / newGrid / 3  ) * newGrid *3 ) ,
			top: (this.props.canvas.height/this.props.canvas.getZoom())/2 - (group.height*group.scaleY)/2,
		})
		this.props.canvas.add(group);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}
	addDoubleStairs() {
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let width = grid*1.5;
		let height = grid*1.5;
		let horizontalGap = 3;
		let verticalGap = 5;
		let startGap = 0.166667;

		let fill = '#f62424',
			stroke = '#096ed4',
			strokeWidth = grid*0.166667;

		let allRect = [],
			topDistance = grid*5;

		for(let i=0 ; i < 10;i++) {
			let left =0;
			if(allRect.length >0){
				let oldRect = allRect[allRect.length-1];
				left = oldRect.left+oldRect.width;
			}
			let newRect = new window.fabric.Rect({
				stroke,
				strokeWidth,
				top:0,
				left,
				fill,
				width,height
			});
			allRect.push(newRect);
			let newRect2 = new window.fabric.Rect({
				stroke,
				strokeWidth,
				top:topDistance,
				left,
				fill,
				width,height
			});
			allRect.push(newRect2)
		}

		let group = new window.fabric.Group([...allRect],
			{top:0,left:0,name:'double-stairs'});

		let newGridLeft = Math.abs(parseInt(Math.round(group.left / grid / 3 )/5))*5+2.5;
		newGridLeft = newGridLeft * grid * 3;
		group.set({
			left: ( newGridLeft - (group.width*group.scaleX)/2 ) ,
			// left: (Math.round(group.left / newGrid / 3  ) * newGrid *3 ) ,
			top: (this.props.canvas.height/this.props.canvas.getZoom())/2 - (group.height*group.scaleY)/2,
		})

		this.props.canvas.add(group);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}

	addSingleStar() {
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let width = this.props.canvas.width;
		let height = this.props.canvas.height;
		let horizontalGap = 3;
		let verticalGap = 5;
		let startGap = 0.166667;
		let color = '#0d0000';

		// top left
		let circle1 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*startGap,
			left:grid*startGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//top right
		let circle2 = new window.fabric.Circle({
			radius: startGap * grid,
			top:grid*startGap,
			left:grid*horizontalGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//middle
		let circle3 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*(verticalGap/2),
			left:grid*(horizontalGap/2),
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//bottom left
		let circle4 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*verticalGap,
			left:grid*startGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//bottom right
		let circle5 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*verticalGap,
			left:grid*horizontalGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		let group = new window.fabric.Group([circle1,circle2,circle3,circle4,circle5],{
			top:0,
			left:0,
			name:'single-five',
		});
		let newGridLeft = Math.abs(parseInt(Math.round(group.left / grid / 3 )/5))*5+2.5;
		newGridLeft = newGridLeft * grid * 3;
		group.set({
			left: ( newGridLeft - (group.width*group.scaleX)/2 ) ,
			// left: (Math.round(group.left / newGrid / 3  ) * newGrid *3 ) ,
			top: (this.props.canvas.height/this.props.canvas.getZoom())/2 - (group.height*group.scaleY)/2,
		})
		this.props.canvas.add(group);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}

	addDoubleStar(){
		let grid = this.props.canvas_grid;   // get dynamic grid from props
		let width = this.props.canvas.width;
		let height = this.props.canvas.height;
		let horizontalGap = 3;
		let verticalGap = 5;
		let startGap = 0.166667;
		let color = '#0d0000';

		// 1st pantagon
		// top left
		let circle1 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*startGap,
			left:grid*startGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//top right
		let circle2 = new window.fabric.Circle({
			radius: startGap * grid,
			top:grid*startGap,
			left:grid*horizontalGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//middle
		let circle3 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*(verticalGap/2),
			left:grid*(horizontalGap/2),
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//bottom left
		let circle4 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*verticalGap,
			left:grid*startGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//bottom right
		let circle5 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*verticalGap,
			left:grid*horizontalGap,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		// 2nd pentagon
		let distance  = grid*5;

		let circle6 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*startGap,
			left:circle1.left+distance,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//top right
		let circle7 = new window.fabric.Circle({
			radius: startGap * grid,
			top:grid*startGap,
			left:circle2.left+distance,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//middle
		let circle8 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*(verticalGap/2),
			left:circle3.left+distance,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//bottom left
		let circle9 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*verticalGap,
			left:circle4.left+distance,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		//bottom right
		let circle10 = new window.fabric.Circle({
			radius:startGap * grid,
			top:grid*verticalGap,
			left:circle5.left+distance,
			originX:'centre',
			originY:'centre',
			fill:color,
			strokeWidth:0
		});

		let group = new window.fabric.Group([circle1,circle2,circle3,circle4,circle5,circle6,circle7,circle8,circle9,circle10],{
			top:0,
			left:0,
			name:'double-five',
		});
		let newGridLeft = Math.abs(parseInt(Math.round(group.left / grid / 3 )/5))*5+2.5;
		newGridLeft = newGridLeft * grid * 3;
		group.set({
			left: ( newGridLeft - (group.width*group.scaleX)/2 ) ,
			// left: (Math.round(group.left / newGrid / 3  ) * newGrid *3 ) ,
			top: (this.props.canvas.height/this.props.canvas.getZoom())/2 - (group.height*group.scaleY)/2,
		})
		this.props.canvas.add(group);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}

	changeFill(color) {
		let activeObject = this.props.canvas.getActiveObject();
		if(!activeObject) return;
		let cat1Items = ['single-five','double-five','single-stairs','double-stairs'];
		// cat 1 check
		if(activeObject.name && cat1Items.includes(activeObject.name)){
			activeObject.getObjects().forEach(object=>{
				object.set({fill:color})
			})
		}
		if(activeObject.type === 'rect' || activeObject.type === 'circle'){
			activeObject.set({fill:color})
		}
		let active_shape_turf = this.props.active_shape_turf;
		active_shape_turf.fill = color;
		this.props.setShapeConfig(active_shape_turf);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}
	changeStroke(color) {
		let activeObject = this.props.canvas.getActiveObject();
		if(!activeObject) return;
		let cat1Items = ['single-five','double-five','single-stairs','double-stairs'];
		// cat 1 check
		if(activeObject.name && cat1Items.includes(activeObject.name)){
			activeObject.getObjects().forEach(object=>{
				object.set({stroke:color})
			})
		}
		if(activeObject.type === 'rect' || activeObject.type === 'circle'){
			activeObject.set({stroke:color})
		}
		let active_shape_turf = this.props.active_shape_turf;
		active_shape_turf.stroke = color;
		this.props.setShapeConfig(active_shape_turf);
		this.props.canvas.renderAll();
		this.updateCanvasState();
	}

	updateCanvasState (){
		let tempConfig = this.props._config;
		if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
			let jsonData = this.props.canvas.toJSON(['custom','name']);
			let canvasAsJson = JSON.stringify(jsonData);
			if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
				let indexToBeInserted = tempConfig.currentStateIndex + 1;
				tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

				let numberOfElementsToRetain = indexToBeInserted + 1;
				tempConfig.canvasState = tempConfig.canvasState.splice(
					0,
					numberOfElementsToRetain
				);
			} else {
				tempConfig.canvasState.push(canvasAsJson);
			}

			tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

			if (
				tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
				tempConfig.currentStateIndex !== -1
			) {
				tempConfig.redoButton = "disabled";
			}
		}
		this.props.setCanvasConfig(tempConfig);
	};

	render() {
		const active_shape_turf_fill = this.props.active_shape_turf_fill;
		const active_shape_turf_stroke = this.props.active_shape_turf_stroke;
		return(
			<>
				<div className="row adddiv">
					<h2>SHAPES</h2>
				</div>
				<div className="templatesmain">
					<div className="add-text-blk-hmenu">
						<div className="font-select">
							<div className="row pt-15 pb-15">
								<CustomColorPane activeColor={active_shape_turf_stroke} title={'Line Color'} colors ={TurfColorHex} colorNames={TurfColorName} changeColor={(color) => {
									this.changeStroke(color);
								}}/>
							</div>
							<div className="row pt-15 pb-15">
								<CustomColorPane activeColor={active_shape_turf_fill} title={'Fill Color'} colors ={TurfColorHex} colorNames={TurfColorName} changeColor={(color) => {
									this.changeFill(color);
								}}/>
							</div>
							{/*<div className="row pt-15 pb-15">
								<TextColorPaneConnect activeColor={active_shape_turf_stroke} title={'Line Color'} type={'turf'} changeColor={(color) => {
									this.changeStroke(color);
								}}/>
							</div>
							<div className="row pt-15 pb-15">
								<TextColorPaneConnect activeColor={active_shape_turf_fill}  title={' Fill Color'} type={'turf'} changeColor={(color) => {
									this.changeFill(color);
								}}/>
							</div>*/}
						</div>
					</div>
					<div style={{marginLeft:'10px',marginRight:'10px'}}>
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<div onClick={()=>{this.addShape(1)}} className="temrows mat-lft-repeat-blk" style={{border:'2px solid rgb(52, 73, 94)',background:'rgb(92, 107, 123)',borderRadius:'15px',padding:'10px'}} >
									<img src={require("./../../../../assets/img/singleFive.png")} alt="Single Five" className="img-responsive" />
								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div onClick={()=>{this.addShape(2)}} className="temrows mat-lft-repeat-blk" style={{border:'2px solid rgb(52, 73, 94)',background:'rgb(92, 107, 123)',borderRadius:'15px',padding:'10px'}} >
									<img src={require("./../../../../assets/img/doubleFive.png")} alt="double five" className="img-responsive" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<div onClick={()=>{this.addShape(3)}} className="temrows mat-lft-repeat-blk" style={{border:'2px solid rgb(52, 73, 94)',background:'rgb(92, 107, 123)',borderRadius:'15px',padding:'10px'}} >
									<img src={require("./../../../../assets/img/singleStairs.png")} alt="Single Stair" className="img-responsive" />
								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div onClick={()=>{this.addShape(4)}} className="temrows mat-lft-repeat-blk" style={{border:'2px solid rgb(52, 73, 94)',background:'rgb(92, 107, 123)',borderRadius:'15px',padding:'10px'}} >
									<img src={require("./../../../../assets/img/doubleStairs.png")} alt="double Stair" className="img-responsive" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<div onClick={()=>{this.addShape(5)}} className="temrows mat-lft-repeat-blk" style={{border:'2px solid rgb(52, 73, 94)',background:'rgb(92, 107, 123)',borderRadius:'15px',padding:'10px'}} >
									<img src={require("./../../../../assets/img/circles.png")} alt="Circle" className="img-responsive" />
								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div onClick={()=>{this.addShape(6)}} className="temrows mat-lft-repeat-blk" style={{border:'2px solid rgb(52, 73, 94)',background:'rgb(92, 107, 123)',borderRadius:'15px',padding:'10px'}} >
									<img src={require("./../../../../assets/img/rectangles.png")} alt="Rectangle" className="img-responsive" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const setShapeConfig = _config =>{
	return{
		type:'SET_TURF_SHAPE_COLOR',
		_config
	}
}
const setCanvasConfig = (_config) => {
	return {
		type: "SET_CONFIG",
		_config,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setShapeConfig,setCanvasConfig
		},
		dispatch,
	)
}

const mapStateToPropsForTurfShapes = state => {
	return {
		_config:state._config,
		canvas: state.canvas,
		canvas_grid: state.canvas_grid,
		active_shape_turf: state.active_shape_turf,
		active_shape_turf_fill: state.active_shape_turf.fill,
		active_shape_turf_stroke: state.active_shape_turf.stroke,
	}
}

const TurfShapesToolbarConnect = connect(
	mapStateToPropsForTurfShapes,mapDispatchToProps
)(TurfShapesToolbar);

class TurfShapesToolbarSidebar extends React.Component {
	closeNav15 =() =>{
		document.getElementById("mySidenav15").style.width = "0";
	}

	render() {
		return (
			<>
				<div
					id="turf-shape"
					className="h100 second-menu fordeskview"
					style={{display: "none"}}
				>
					<TurfShapesToolbarConnect/>
				</div>
				<div
					className="text-center h100 pad0 mtopneg sidenav4 formobview wo"
					id="mySidenav15"
				>
					<a
						style={{color: "#fff"}}
						href="#"
						className="closebtn"
						onClick={() => this.closeNav15()}
					>
						&times;
					</a>
					<TurfShapesToolbarConnect/>
				</div>
			</>
		);
	}
}

export default TurfShapesToolbarSidebar